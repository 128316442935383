import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import scrollTo from 'gatsby-plugin-smoothscroll'
import StoreContext from '~/context/StoreContext'
import {  Badge, Layout, Menu  } from 'antd'
import 'antd/dist/antd.css'

const { Header } = Layout;

const ResponsiveNavBar = (props) => {
  //const { checkout } = useContext(StoreContext)
  return (
    <Header className="navigation-bar">
      <Menu theme="dark" mode="horizontal" className="nav-menu">
        { props.links.map(link => (
            <Menu.Item key={`nav-${link.title}`} className="nav-menu-item">
              { link.badge ? <Badge count={props.count}><a href={link.link}>{link.title}</a></Badge> : <a href={link.link}>{link.title}</a> }
            </Menu.Item>
        )) }
      </Menu>
    </Header>
  )
}

export default ResponsiveNavBar
