import React from 'react'
import { Layout, Row, Col } from 'antd'
const { Footer } = Layout

const ColumnFooter = (props) => {
  return(
    <Footer id="footer">
      <Row>
        {
          props.columns.map( (col,index) => (
            <Col span={props.columns.length} className="footer-column">
              {  col.links.map( (link,index) => ( <a className="footer-link" href={link.ref}>{link.title}</a>) ) }
            </Col>
          ) )
        }
      </Row>
    </Footer>
  )
}

export default ColumnFooter
