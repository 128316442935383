import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import { Layout } from 'antd'
import ColumnFooter from '~/components/ColumnFooter'

import 'antd/dist/antd.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../css/main.css'

import { StoreContext } from '~/context/StoreContext'

import Logo from '../../../resources/icons/logo.png'
import ResponsiveNavBar from '~/components/Navigation/ResponsiveNavBar'

const { Content } = Layout

let links = [
{
  'title': 'SHOP',
  'link': '/#store'
},
{
  'title': 'CART',
  'link': '/cart',
  'badge': true
}];

let columns = [
  {
    links: [
      {
        ref:'/',
        title:'home'
      },
      {
        ref: '/policies',
        title: 'returns&policies'
      },
      {
        ref: '/support',
        title: 'support'
      }
    ]
  }
]

const MainLayout = ({ children }) => {
  const { store: { checkout: { lineItems } } } = useContext(StoreContext)
  return (
      <StaticQuery
        query={graphql`
          query MainSiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Layout>
               <ResponsiveNavBar links ={ links } count={ lineItems.reduce((accum, prev) => ( accum + prev.quantity), 0) }/>
                <Content id="main">
                    {children}
                  </Content>
                  <ColumnFooter columns={columns}/>
            </Layout>
        )}
      />
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
